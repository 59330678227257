export const TRANSLATIONS_EN = {
  notification: {
    level: {
      title: 'Congratulations!',
      text1: 'You have reached the next level of the loyalty program.',
      text2: 'Now you can save up to {{percent}}% from each order in bonus points.',
      text3: 'A detailed description of the levels can be found in your personal account.',
      link: 'Open account page'
    },
    join: {
      title: 'Ciao!',
      text: 'We have launched a loyalty program where you can earn and redeem points, and also get access to our special offers.',
      link: 'Complete registration'
    },
    registered: {
      title: 'You have signed up for the Maestrello loyalty program!',
      text: 'Enjoy 300 welcome points, which can be used to pay up to 30% of the order Buon appetito!',
      footerText: 'Buon appetito!'
    }
  },
  offer: {
    label: 'Offers',
    header: 'Refer a friend and get bonus points',
    description: 'Invite a friend to the Maestrello loyalty program and get 300 bonus points as a gift after his or her first order',
    link: 'Copy link for a friend',
    link_copied: 'Link copied'
  },
  levels: {
    levelChanged: {
      congratulationsTitle: 'Congratulations!',
      congratulationsLine1: 'You have reached the next level of the loyalty program.',
      congratulationsLine2: 'Now you can save up to {{percent}}% from each order in bonus points. Also enjoy {{extra}} extra bonus points! Nice! Thanks. 💛'
    },
    one: {
      label: 'Fan',
      label_alt: 'Fan',
      head_text: 'Get 5% of the order price back in bonus points',
      bonus: '300 bonus points for registration'
    },
    two: {
      label: 'Enthusiast',
      label_alt: 'Enthusiast',
      head_text: 'Get 7% of the order price back in bonus points',
      bonus: '400 bonus points for leveling up',
      info: 'Spend 20,000 rubles on orders in total, excluding the cost of courier delivery, and become an Enthusiast',
    },
    three: {
      label: 'Hedonist',
      label_alt: 'Hedonist',
      head_text: 'Get 10% of the order price back in bonus points',
      bonus: '500 bonus points for leveling up',
      info: 'Spend 40,000 rubles on orders in total, excluding the cost of courier delivery, and become a Hedonist'
    }
  },
  promo: {
    blockLoyalty: {
      front_title0: 'Join',
      front_title1: 'the loyalty',
      front_title2: 'program',
      front_title3: 'Maestrello',
      back_subtitle0: 'Save up and write off points',
      back_subtitle00: 'and get access to special offers.',
      back_subtitle1: 'The registration bonus is 300 points.',
      back_subtitle2: '1 point - 1 ruble.'
    },
    blockDiscount: {
      front_title0: '10% discount',
      front_title1: 'on delivery',
      front_subtitle0: 'from Monday to Thursday',
      back_subtitle0: 'For all orders placed from',
      back_subtitle1: 'Monday to Thursday',
      back_subtitle2: 'from 11:00 to 16:00'
    },
    blockPickup: {
      first_title0: 'Ciao! This is',
      first_title1: 'Maestrello',
      first_title2: 'Pick-up Point',
      front_subtitle0: '',
      back_title0: 'Maestrello',
      back_subtitle0: 'Order your favorite pizza di Roma ',
      back_subtitle1: 'ahead of time and never wait in line again',
      back_subtitle2: '',
      back_subtitle3: ''
    },
    blockCertificate: {
      front_title0: 'Сертификат',
      front_title1: 'Maestrello',
      back_subtitle0: 'The most delicious gift to a friend, colleague or family. The certificate can be used for delivery or in our pizzerias.',
      back_subtitle1: 'You can purchase a certificate online in the “Certificate” menu section or in our pizzerias.'
    },
    blockSanta: {
      first_title: 'Christmas act in support of low-income families and large families',
      first_title2: 'Become a secret Santa with us. Christmas act in support of low-income families and large families'
    },
    blockWorkTime: {
      front_title: 'Delivery working hours in holidays',
      back_title: 'Delivery working hours in holidays',
      descriptionDate1: 'December, 31st',
      descriptionTime1: '11:00 - 16:30',
      descriptionDate2: '1st January is not working day',
      descriptionTime2: '',
      descriptionDate3: '2nd January',
      descriptionTime3: '14:00 - 21:30',
      descriptionDate4: '3rd January',
      descriptionTime4: '12:00 - 21:30',
      descriptionDate5: '4th January',
      descriptionTime5: '12:00 - 21:30',
      descriptionDate6: 'From January 5, as usual',
      descriptionTime6: ''
    },
    blockValentine: {
      front_title0: 'Will you be my',
      front_title1: 'Valentine?',
      back_title: 'Will you be my Valentine?',
      back_subtitle0: 'С 10 по 19 февраля вы можете добавить к заказу свечу и подсвечник от наших друзей из Home Owner.',
      back_subtitle1: 'Всё для романтического ужина можно найти в меню и корзине.',
    },
    blockMargherita: {
      front_title0: 'Get a mini',
      front_title1: 'Margherita',
      front_title2: 'for free',
      back_subtitle0: 'Make an order over 1500 rubles and get a mini Margherita',
      back_subtitle1: 'for free. It will be added to the cart when the required',
      back_subtitle2: 'order amount is reached'
    }
  },
  common: {
    account: 'Account',
    login: 'Login',
    delivery_menu: 'Delivery menu',
    aboutUs: 'About Us',
    delivery: 'Delivery',
    myOrders: 'My orders',
    catering: 'Catering',
    legal: 'More information',
    cart: 'Cart',
    back: 'Back',
    close: 'Close',
    loading: 'loading...',
    make_order: 'Make order',
    sww: 'Something went wrong',
    sww404: 'Something went wrong, we don\'t have such a page. But there is pizza.',
    menuLink: 'View pizza',
    cookies: 'Cookies are essential for the website to function properly. We use cookies to ensure the security of entering data on the site, storing them, collecting statistics, optimizing the site and presenting content. To continue working with the site, click "Agree and continue". You can read a detailed description of the types of cookies used and make a decision about working with them by clicking the "More Information" link.',
    cookie_button_text: 'Agree and continue',
    language: 'Site language',
    checkCode: 'SMS code',
    checkCodeAction: 'Check code',
    checkCodeError: 'Code is incorrect',
    checkCodeTimeout: 'Repeat after {{timeOut}} sec',
    repeatCheckCode: 'Resend SMS code',
    phoneChecked: 'Phone checked',
    changePhoneNumber: 'изменить номер',
    agreement_processing: 'consent to the processing of initial data, familiarization with the user agreement, privacy policy',
    agreement_marketing: 'I want to be notified about new products and special offers',
    open: 'go'
  },
  dishes: {
    allergens: 'Allergens: ',
    weight: 'Weight',
    protein: 'Protein: ',
    fat: 'Fat: ',
    carbohydrates: 'Carbohydrates: ',
    g: 'g.',
    ml: 'ml.',
    kcal: 'kcal for 100g.',
    addToCart: 'Add to Cart',
    addToPizza: 'Add to Pizza',
    close: 'Close'
  },
  formValidation: {
    required: 'Required field',
    minlength7: 'Minimum 8 characters',
    incorrectAddress: 'Incorrect Address',
    incorrectFormat: 'Incorrect Format',
    incorrectPhone: 'Incorrect Phone number',
    incorrectCode: 'Incorrect code',
    incorrectNumber: 'incorrect Number',
    incorrectDateTime: 'Incorrect time / date',
    incorrectDateTimeToEarly: 'Please select a future date',
    notPrecisionAddress: 'Inaccurate address',
    notFullAddress: 'Incomplete address',
    specifyHouseNumber: 'Specify the house number.',
    specifyAddress: 'Specify the address.',
    incorrectEmailOrPhone: 'Incorrect address or phone',
    notExact: 'Passwords not exact',
    passwordDifficult: 'The password has at least one uppercase letter, one lowercase, one digit and one special character',
    passwordLong: 'The password is at least 8 characters long',
    passwordUpperCaseLetter: 'The password has at least one uppercase letter',
    passwordLowerCaseLetter: 'The password has at least one lowercase letter',
    passwordOneDigit: 'The password has at least one digit',
    passwordSpecCharacter: 'The password has at least one special character'
  },
  page: {
    close: 'Закрыть',
    apply: 'Apply',
    readLink: 'Read',
    about: {
      phone: 'Phone number',
      email: 'Email',
      socialNetworks: 'Social networks',
      instagram: 'instagram',
      telegram: 'telegram',
      vkontakte: 'vkontakte',
      facebook: 'facebook',
      pizzeriaAddresses: 'Pizzeria addresses',
      pokrovka: 'Moscow, Pokrovka, 16',
      mitnaya: 'Moscow, Danilovsky market, Mytnaya, 74',
      petrovka: 'Moscow, Petrovka, 30/7',
      gruzinka: 'Moscow, Bolshaya Gruzinskaya, 76',
      depo: 'Moscow, Three vokzala, Depo, Novoryazanskaya, 23c1',
      deliveryHours: 'Delivery working hours',
      readAboutUs: 'Read about us',
      deliveryRules: 'Delivery terms',
      deliveryPrice: 'Delivery price',
      deliveryMinPrice: 'Minimum order amount',
      deliveryMinPriceDescription: '700₽ excluding shipping costs',
      deliveryTime: 'Time of delivery',
      deliveryTimeDescription: 'Calculated individually at the time of ordering',
      deliveryTimeDescriptionSms: 'After placing your order, expect an SMS with confirmation',
      MonThu: 'Mon-Thu',
      FriSat: 'Fri-Sat',
      Sun: 'Sun',
      MonSun: 'Mon-Sun',
      MonFri: 'Mon-Fri',
      SatSun: 'Sat-Sun',
      intro0: 'We are Maestrello — a chain of modern Italian pizzerias launched in 2017 by three Italians: Giulia Maestrello, Matteo Castagna and Fabio Bressan.',
      intro1: 'The main difference of our Roman pizza — handmade, carefully selected ingredients and unique dough. We mix it from three types of flour: soy, wheat, rice. Because of this, the pizza is light and there will always be a place for another one piece!'
    },
    delivery: {
      address: 'Shipping address',
      notAvailable: 'Shipping is not available at this address',
      tooFar: 'Too far',
      dragMe: 'Drag me',
      typeAddress: 'Start typing the address',
      price: 'Delivery cost: {{price}} ₽',
      time: ' | ~ {{time}} min.',
      deliveryTimeLine1: 'Delivery working hours',
      deliveryTimeLine2: 'Mon-Fri 10:00-21:30',
      deliveryTimeLine3: 'Sat-Sun 10:00-21:30',
      descriptionLine1: 'Costs and delivery time are calculated individually, depending on which one of the three different delivery areas you order from. Minimum order amount 700₽ excluding delivery costs. After placing your order, you will receive an SMS with confirmation.',
      descriptionLine2: 'After placing your order, you will receive an SMS with confirmation.'
    },
    pickupPoint: {
      header: 'Ciao! It’s Maestrello Pick-up Point - your assistant with contactless ordering of crispy Roman pizzas, salads, soups and desserts without a queue. Going home soon from your office or taking a nice walk in the center and feeling like getting a crunchy pizza di Roma to go? Choose from our menu your favorite food or drink, indicate a pick up time that suits you best and come get your order at our brand new location on Petrovka 30/7. Easy, fast and contactless.',
      stage1: 'Go to the website using your phone or computer',
      stage2: 'Add order to cart',
      stage3: 'Choose the option "Pickup" and our pizzeria on Petrovka 30/7',
      stage4: 'Indicate a pickup time and pay for the order (only online payments)',
      stage5: 'You’ll receive an SMS with your order number',
      stage6: 'Get your order with the indicated number from our Petrovka pickup point',
      footer: 'If you have any difficulties or questions on the spot, contact the cashiers.'
    },
    santa: {
      counter: 'Purchased pizzas',
      header: 'Santa Pizza - Christmas act of charity in support of low-income families of Basmanny, Tver and Danilovsky districts. The promotion runs from December 23 to January 7.',
      subheader: 'What do you need to do to become a secret Santa and present children with?',
      stage1: '— select one or more Santa pizzas in the "Santa Pizza" menu section',
      stage2: '— pay half of the price (we will cover the other half)',
      stage3: '*if you decide not to order anything online, you can still give Santa a pizza! To do this, put one or more Santa pizzas in the basket and order pickup from any pizzeria.',
      stage4: 'At the end of the promotion, we will send all the “Santa Pizza”, together we’ll give joy and attentions to those who need it the most.',
      stage5: 'Follow the results of your kindness on our insta: @pizzamaestrello'
    },
    conditions: {
      paymentsConditions: 'Terms of payment',
      paymentsOptions: 'Payment option',
      byCard: 'By card for pick-up from the restaurant',
      byCash: 'Cash to the courier or by  card on the website when ordering delivery',
      byInvoice: 'Cashless payment by invoice',
      paymentText: 'To pay by card, click on the "Pay by card" button on the ordering page. Payment can be made using credit cards of the following payment systems:',
      dataTransfer: 'Data transfer',
      paymentText2: 'To pay for the purchase, you will be redirected to the bank\'s payment gateway to enter your card details.Please prepare your plastic card in advance.The connection with the payment gateway and the transfer of information is carried out in a secure mode using the SSL encryption protocol.The confidentiality of the reported personal information is ensured by the Russian Standard Bank. The entered information will not be provided to third parties, except in cases provided for by the legislation of the Russian Federation. Bank card payments are carried out in strict accordance with the requirements of Visa Int. and MasterCard Europe Sprl.',
      paymentProcess: 'Payment process',
      paymentText3: 'When paying with a bank card, the payment for the order will take place immediately after the completion of the order in our online store.After confirming the order, click on the "Payment by card" button, and the system will switch you to the authorization server page, where you will need to enter the card details, initiate its authorization, and then return to the online store by clicking on the "Return to the store" button. Then the system will notify you of the authorization results.Upon confirmation of authorization, we will automatically start preparing your order. In case of refusal to authorize the card, try to repeat the payment procedure.',
      orderCancellation: 'Cancellation of order',
      paymentText4: 'When canceling a paid order, you can order other products from the menu or get a refund of the entire amount on the card by contacting the operator by phone +79166338777 or by writing to e-mail: delivery@maestrello.ru'
    },
    auth: {
      login: 'Email or phone number',
      password: 'Password',
      passwordCopy: 'Repeat password',
      incorrectValues: 'Incorrect',
      create: 'Sign up',
      enter: 'Log in',
      passwordLost: 'Forgot password',
      singUp: 'Sing up',
      wasSingUp: 'Registered?',
      firstName: 'First name',
      lastName: 'Last name',
      name: 'Name',
      birthday: 'Date of birth',
      gender: 'Gender',
      gender_male: 'Male',
      gender_female: 'Female',
      gender_not_selected: 'Gender not selected',
      placeholderName: 'Nikol',
      phone: 'Phone',
      email: 'E-Mail',
      createAccount: 'Create Account',
      recoverPassword: 'Recover Password',
      newPassword: 'New Password',
      changePassword: 'Change Password',
      changePasswordSuccess: 'Password changed successfully.',
      smsCode: 'SMS code',
      recover: 'Recover',
      emailOrPhone: 'Email or phone',
      registerError: 'The user already exists. Use password recovery.',
      newPasswordSendTo: '{{address}} sent a link to change password.',
      time: '{{time}} minutes',
      address_save_btn: 'Yes, correct!'
    },
    account: {
      addAddress: 'Add address',
      orders: 'Orders',
      viewAllOrders: 'View all orders',
      totalOrders: 'Total {{total}} orders',
      repeatOrder: 'Repeat order',
      addresses: 'Shipping address',
      address: 'My shipping address',
      points: 'My points',
      point: 'point',
      logOut: 'Log out',
      rulesLevels: 'Terms of levels and bonuses',
      entrance: 'Entrance number ',
      intercom: 'Intercom code ',
      floor: 'Floor number',
      apartment: 'Apartment number ',
      comment: 'Comment',
      addressString: '{{entrance}} Floor {{floor}}{{apartment}}{{intercom}}{{comment}}',
      changeInfo: 'Change info ',
      deleteAddress: 'Delete address',
      allOrders: 'All orders',
      addCard: 'Add card',
      toWallet: 'to Wallet',
      addToWallet: 'Add в to Wallet',
      fillProfileFields: 'Fill in the profile data and install an electronic card in Wallet to participate in the loyalty program',
      fillProfileFieldsAction: 'Open Profile.'
    },
    cart: {
      link: 'Cart',
      empty1: 'Your cart is empty',
      empty2: 'Let\'s add something?',
      shippingMenu: 'Delivery menu',
      free: 'Free',
      numberOfCutlery: 'Number of cutlery',
      giftInBasket: 'Gift in the basket',
      pizzaAsGift: 'Get a mini pizza for free',
      addToOrder: 'Add to order',
      desertGiftFriend: 'Dessert as a gift for a friend brought',
      desertGiftLevelChange: 'Congratulations and give a dessert or fruit drink of your choice as a gift!',
      pizzaGiftLevelChange: 'Congratulations and give one of the five pizzas as a gift!',
      miniAdded: 'Mini Margarita has been added to the cart. Bon Appetit!',
      miniToAdd: 'With other {{price}} ₽ order you’ll have a mini pizza for free Wanna order more?',
      additional: 'Additional:',
      addIngredients: 'Add ingredients',
      checkout: 'Checkout',
      loyalty: 'loyalty program',
      romanticDinner: 'Всё для романтического ужина',
    },
    order: {
      orderNum: 'Order №',
      status: 'Status:',
      readyTime: 'Will be ready for ',
      deliveryTime: 'We deliver to ',
      goToPayment: 'Pay',
      saveAddress: 'Save address',
      notSaveAddress: 'No thanks',
      saveAddressText: 'Do you want to save the address? This will help you order your pizza faster next time.',
      shippingNotAvailable: 'delivery not available at this address.',
      delivery: 'Delivery',
      deliveryTimeCost: 'from {{min}} min / {{cost}} ₽',
      deliveryCourier: 'Delivery',
      deliveryPickup: 'Pickup',
      address: 'Shipping address*',
      entrance: 'Entrance number*',
      intercom: 'Intercom code',
      floor: 'Floor number*',
      apartment: 'Apartment number',
      comment: 'Comment',
      pickupPokrovka: 'Pokrovka, 16',
      pickupMitnaya: 'Mytnaya, 74',
      pickupGruzinka: 'Bolshaya Gruzinskaya, 74',
      pickupPetrovka: 'Petrovka, 30/7',
      pickupDepo: 'Novoryazanskaya, 23c1',
      selectReadyTime: 'Ready time',
      selectDeliveryTime: 'Time of delivery',
      soonAsPossible: 'As soon as possible',
      selectTime: 'Choose the time',
      date: 'Date',
      time: 'Time',
      min: '{{minutes}} min',
      makeOrder: 'Make order',
      deliveryNotWork: 'Delivery no longer work, but you can choose a different delivery time or order self-pickup.',
      kitchenClosed: 'The kitchen is currently closed, but you can pre-order.',
      minimumDelivery: 'Delivery is available for orders over 700₽ excluding delivery.',
      orderTotal: 'Order total: {{cost}} ₽',
      orderNew: 'New',
      orderPaid: 'Paid',
      orderProcessing: 'Processing',
      orderForwarded: 'Forwarder to Restaurant',
      orderAccepted: 'Accepted by Restaurant',
      orderReadyToPickup: 'Order Ready to Pickup',
      orderAwaitingPickup: 'Order Awaiting to Pickup',
      orderShipped: 'Order Shipped',
      orderDelivered: 'Order Delivered',
      orderCompleted: 'Completed',
      orderCanceled: 'Canceled',
    },
    payment: {
      name: 'Payment options',
      options: 'Choose payment option',
      conditions: 'Conditions',
      byCard: 'Pay by card',
      byCardTest: 'Pay by card test',
      byCash: 'Pay by cash',
      byCashSecondLine: 'When receiving',
      byInvoice: 'Pay by Invoice',
      byInvoiceSecondLine: 'Bank transfer (for a company)',
      change: 'From what amount do you need change?*',
      discount: 'Discount coupon',
      checkPromo: 'Check coupon',
      promoActivated: 'Promo code activated',
      promoInvalid: 'Promo code is invalid',
      confirmByCard: 'Confirm and pay',
      confirmByCash: 'Confirm the order',
      pay: 'Pay by Card',
      checkout: 'Checkout',
      optionDiscount: 'Get 5% discount for pre-order',
      optionSavePoints: 'Accumulate points',
      optionWriteOffPoints: 'Write off points',
      point_zero: '{{count}} point',
      point_one: '{{count}} point',
      point_other: '{{count}} points',
      writeOffDescription: 'Pay up to {{count}}% of the order amount with points'
    }
  }
};
